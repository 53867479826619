import React from 'react';
import {
  Datagrid,
  Title,
  ListBase,
  TextField,
  TopToolbar,
  ExportButton,
  SimpleListConfigurable,
  DateField,
  Pagination,
  TextInput,
  useNotify,
  useRedirect,
} from 'react-admin';
import { EmptyFieldWithRecord } from '../../Shared/EmptyFieldWithRecord';
import { Stack, useMediaQuery } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import styled from 'styled-components';
import { FilterForm } from '../../Shared/FilterForm';
import AdminLogParamsDetail from './AdminLogParamsDetail';
import { WithLoading } from '../../Shared/Loading';
import { useResetList } from '../../Shared/List.hooks';

const StyledTextInput = styled(TextInput)`
  .MuiInputBase-input:focus ~ div .MuiButtonBase-root {
    color: #ff5722;
  }
`;

const filters = (fullWidth: boolean): React.ReactNode[] => {
  return [
    <StyledTextInput
      key="filterSearchInput"
      label="Search"
      source="q"
      alwaysOn
      fullWidth={fullWidth}
      resettable
    />,
  ];
};

const ListActions: React.FC = () => {
  const isSmallDevice = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );

  return (
    <Stack
      direction={isSmallDevice ? 'column' : 'row'}
      marginTop={2}
      marginBottom={2}
    >
      <FilterForm filters={filters(isSmallDevice)} fullWidth={isSmallDevice} />
      <TopToolbar
        sx={{
          backgroundColor: 'transparent!important',
          width: '100%',
        }}
      >
        <ExportButton />
      </TopToolbar>
    </Stack>
  );
};

const AdminList: React.FC = () => {
  const isSmallDevice = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );
  const notify = useNotify();
  const redirect = useRedirect();

  useResetList();

  const onError = (error: any): void => {
    notify(`Could not load list: ${error.message}`, { type: 'warning' });
    redirect('/dashboard');
  };

  const handleParamsClick = (event: React.MouseEvent<any>): void => {
    event.stopPropagation();

    const expandIcon: HTMLElement = (event.target as HTMLElement).parentElement
      ?.parentElement?.firstChild?.firstChild as HTMLElement;

    expandIcon && expandIcon.click();
  };

  return (
    <ListBase
      debounce={1000}
      perPage={25}
      queryOptions={{ onError }}
      sort={{ field: 'id', order: 'ASC' }}
    >
      <Title title="Galactic Entertainment" />
      <ListActions />

      <WithLoading>
        <>
          {isSmallDevice ? (
            <SimpleListConfigurable
              primaryText={(record) => record.email}
              secondaryText={(record) => record.route}
              linkType="show"
            />
          ) : (
            <Datagrid
              bulkActionButtons={false}
              expand={<AdminLogParamsDetail />}
              isRowExpandable={(row) => {
                const isEmptyObject =
                  row.params && Object?.keys(row?.params)?.length === 0;
                return !isEmptyObject && row.params;
              }}
              optimized
              rowClick="show"
            >
              <TextField source="id" />
              <EmptyFieldWithRecord source="email" label="E-mail">
                <TextField source="email" />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord source="route">
                <TextField source="route" />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord source="method">
                <TextField source="method" />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord source="duration">
                <TextField source="duration" />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord source="objectId" label="Object ID">
                <TextField source="objectId" />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord source="params">
                <TextField
                  source="params"
                  style={{
                    cursor: 'pointer',
                    display: 'inline-block',
                    maxWidth: '20em',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                  sx={{
                    '&:hover': {
                      color: '#ff5722',
                    },
                  }}
                  onClick={handleParamsClick}
                />
                <LaunchIcon
                  sx={{
                    cursor: 'pointer',
                    fontSize: 15,
                    ml: 1,
                    '&:hover': {
                      color: '#ff5722',
                    },
                  }}
                  onClick={handleParamsClick}
                />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord source="createdAt" label="Created At">
                <DateField source="createdAt" style={{ fontStyle: 'italic' }} />
              </EmptyFieldWithRecord>
            </Datagrid>
          )}
          <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
        </>
      </WithLoading>
    </ListBase>
  );
};

export default AdminList;
