/**
 * Create a "Jan 19, 2021 at 20:41" like formatted date
 * from date string
 *
 * @param dateStr Date
 * @returns string
 */
export const getFormattedDateText = (dateStr: string): string => {
  const date = new Date(dateStr);
  const formattedDate = `
    ${date.toLocaleString('default', {
      month: 'short',
    })} ${date.getDate()}, ${date.getFullYear()} ${[
    date.getHours(),
    date.getMinutes(),
  ].join(':')}
  `;

  return formattedDate;
};
