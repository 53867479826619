import React from 'react';
import { Typography } from '@mui/material';
import { Title } from 'react-admin';

type HeaderTitleProps = {
  title?: string;
};

const HeaderTitle: React.FC<HeaderTitleProps> = ({
  title = 'Galactic Entertainment',
}) => {
  return (
    <Typography variant="h6" gutterBottom marginBottom="0">
      <Title title={title} />
    </Typography>
  );
};

export default HeaderTitle;
