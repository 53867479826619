import styled from 'styled-components';

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 24px;

  [class$='-RaLoading-root'] {
    margin-top: 0;
    height: auto;
  }
`;

export { LoadingWrapper };
