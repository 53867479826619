import React, { FC } from 'react';
import {
  ChipField,
  DateField,
  FunctionField,
  ImageField,
  Labeled,
  Show,
  TextField,
  RichTextField,
  usePermissions,
} from 'react-admin';
import { EmptyFieldWithRecord } from '../../Shared/EmptyFieldWithRecord';
import {
  AdminAccessRightListEnum,
  AdminStatusLabelMap,
} from '../../Shared/Admin.types';
import { useMediaQuery } from '@mui/material';
import ResourceDetailTopToolbar from '../../Shared/ResourceDetailTopToolbar/ResourceDetailTopToolbar';
import { HeaderTitle } from '../../Shared/HeaderTitle';

import * as S from './PartnerShow.styled';
import { checkPermission } from '../../Admin/Admin.utilities';
import { PartnerEdit } from '../PartnerEdit';

const PartnerShow: FC = () => {
  const isSmallDevice = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );
  const { permissions } = usePermissions();

  return (
    <Show
      emptyWhileLoading
      actions={
        <ResourceDetailTopToolbar
          resource="Partner"
          hasBackButton
          hasEditButton={
            checkPermission(permissions, [
              AdminAccessRightListEnum.UpdatePartners,
            ])
              ? true
              : false
          }
        />
      }
      title={<HeaderTitle />}
      sx={{ paddingBottom: isSmallDevice ? '1rem' : 'auto' }}
    >
      <S.StyledCard isSmallDevice={isSmallDevice}>
        <EmptyFieldWithRecord source="logo">
          <FunctionField
            render={(record: any) => {
              return (
                <ImageField
                  source="logo"
                  record={{
                    logo: record.logo,
                  }}
                  title={record.logo}
                />
              );
            }}
          />
        </EmptyFieldWithRecord>
        <Labeled label="Name:">
          <EmptyFieldWithRecord source="name">
            <TextField source="name" />
          </EmptyFieldWithRecord>
        </Labeled>
        <Labeled label="Description:">
          <EmptyFieldWithRecord source="description">
            <TextField source="description" />
          </EmptyFieldWithRecord>
        </Labeled>

        <Labeled label="Website:">
          <EmptyFieldWithRecord source="website">
            <TextField source="website" />
          </EmptyFieldWithRecord>
        </Labeled>
        <Labeled label="Category:">
          <EmptyFieldWithRecord source="category">
            <ChipField source="category" />
          </EmptyFieldWithRecord>
        </Labeled>
        <Labeled label="Type:">
          <EmptyFieldWithRecord source="type">
            <ChipField source="type" />
          </EmptyFieldWithRecord>
        </Labeled>
        <Labeled label="Financial Info:">
          <EmptyFieldWithRecord source="financialInfo">
            <RichTextField source="financialInfo" />
          </EmptyFieldWithRecord>
        </Labeled>
        <Labeled label="Contact Info:">
          <EmptyFieldWithRecord source="contactInfo">
            <RichTextField source="contactInfo" />
          </EmptyFieldWithRecord>
        </Labeled>
        <Labeled label="Status:">
          <EmptyFieldWithRecord source="status">
            <FunctionField
              render={(record: any) => {
                return (
                  <ChipField
                    source="status"
                    record={{
                      status: AdminStatusLabelMap[record?.status as string],
                    }}
                    style={{
                      backgroundColor:
                        record?.status === '1' ? '#ff5722' : '#DEDEDE',
                      color: record?.status === '1' ? '#ffffff' : '#000000',
                    }}
                  />
                );
              }}
            />
          </EmptyFieldWithRecord>
        </Labeled>
        <Labeled label="Created By:">
          <EmptyFieldWithRecord source="createdBy" label="Created By">
            <TextField source="createdBy" />
          </EmptyFieldWithRecord>
        </Labeled>
        <Labeled label="Updated By:">
          <EmptyFieldWithRecord source="updatedBy" label="Updated By">
            <TextField source="updatedBy" />
          </EmptyFieldWithRecord>
        </Labeled>
        <Labeled label="Updated At:">
          <EmptyFieldWithRecord source="updatedAt" label="Updated At">
            <DateField source="updatedAt" />
          </EmptyFieldWithRecord>
        </Labeled>
      </S.StyledCard>
    </Show>
  );
};

export default PartnerShow;
