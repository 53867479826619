import { useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';
import { useLogin } from 'react-admin';
import { User } from '../Shared/User/User.types';
import { SOCIAL_AUTH_SERVER_URL } from '../App.config';

const socket = io(SOCIAL_AUTH_SERVER_URL);

type Callback = (user: User) => void;
type ReturnType = {
  startAuth(newCallback?: Callback): void;
  isAuthorized: boolean;
  user: User | undefined;
  disabled: boolean;
};

const useOAuth = (): ReturnType => {
  const [user, setUser] = useState<User>();
  const [disabled, setDisabled] = useState('');
  const [callback, setCallback] = useState<Callback>();
  const popup = useRef<Window>();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const provider = 'internal/google';
  const socketEventName = 'internalgoogle';
  const login = useLogin();

  useEffect(() => {
    socket.on(socketEventName, (data: User) => {
      if (popup.current) {
        popup.current.close();
      }

      if (data.accessToken) {
        setUser(data);
        setIsAuthorized(true);

        callback && callback(data);
      }
    });
  }, [callback, login, popup]);

  const checkPopup = (): void => {
    const check = setInterval(() => {
      if (
        !popup.current ||
        popup.current.closed ||
        popup.current.closed === undefined
      ) {
        clearInterval(check);
        setDisabled('');
      }
    }, 3000);
  };

  const openPopup = (): any => {
    const width = 600,
      height = 600;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;
    const url = `${SOCIAL_AUTH_SERVER_URL}/${provider}?socketId=${socket.id}`;

    return window.open(
      url,
      '',
      `toolbar=no, location=no, directories=no, status=no, menubar=no, 
      scrollbars=no, resizable=no, copyhistory=no, width=${width}, 
      height=${height}, top=${top}, left=${left}`
    );
  };

  const startAuth = (newCallback: Callback): void => {
    if (!disabled) {
      setCallback(newCallback);
      popup.current = openPopup();
      checkPopup();
      setDisabled('disabled');
    }
  };

  return {
    startAuth,
    isAuthorized,
    user,
    disabled: disabled === 'disabled',
  };
};

export default useOAuth;
