import * as React from 'react';
import { useNotify, useRedirect, Create } from 'react-admin';
import { PartnerForm } from '../../Shared/PartnerForm';
import { CustomTopToolbarActions } from '../../Shared/CustomTopToolbarActions';
import HeaderTitle from '../../Shared/HeaderTitle/HeaderTitle';

type FormField = any;
type FormData = any;

const PartnerCreate: React.FC = () => {
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = (data: FormData): void => {
    notify(`Post created successfully`);
    redirect('edit', 'Partners', data.id, data);
  };

  /**
   * Removes the fields with an empty string value from object.
   *
   * @param data Partner
   * @returns Partner
   */
  const transform = (data: FormData): FormData => {
    const sanitizedData: { [key: string]: FormField } = {};

    for (const key in data) {
      sanitizedData[key] = data[key];
    }
    return sanitizedData;
  };

  return (
    <Create
      transform={transform}
      mutationOptions={{ onSuccess }}
      title={<HeaderTitle />}
      actions={<CustomTopToolbarActions title="Create Partner" hasBackButton />}
    >
      <PartnerForm />
    </Create>
  );
};

export default PartnerCreate;
