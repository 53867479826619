import { User } from './User/User.types';

export enum AdminAccessRightListEnum {
  'AllPermissions' = '*',
  'ManageQuests' = '/quests/*',
  'ReadQuests' = '/quests/:id|GET',
  'CreateQuests' = '/quests/:id|POST',
  'UpdateQuests' = '/quests/:id|PUT',
  'ManageAirDropQuests' = '/adquests/*',
  'ReadAirDropQuests' = '/adquests/:id|GET',
  'CreateAirDropQuests' = '/adquests/:id|POST',
  'UpdateAirDropQuests' = '/adquests/:id|PUT',
  'ManageStoryInterSeason' = '/storyinterseason/*',
  'ReadStoryInterSeason' = '/storyinterseason/:id|GET',
  'CreateStoryInterSeason' = '/storyinterseason/:id|POST',
  'UpdateStoryInterSeason' = '/storyinterseason/:id|PUT',
  'ManageStoryFollowUp' = '/storymessages/*',
  'ReadStoryFollowUp' = '/storymessages/:id|GET',
  'CreateStoryFollowUp' = '/storymessages/:id|POST',
  'UpdateStoryFollowUp' = '/storymessages/:id|PUT',
  'ManageAdmins' = '/admins/*',
  'ReadAdmins' = '/admins/:id|GET',
  'CreateAdmins' = '/admins/:id|POST',
  'UpdateAdmins' = '/admins/:id|PUT',
  'ManageAdminlogs' = '/adminlogs/*',
  'ReadAdminlogs' = '/adminlogs/:id|GET',
  'ManagePartners' = '/partners/*',
  'ReadPartners' = '/partners/:id|GET',
  'CreatePartners' = '/partners/:id|POST',
  'UpdatePartners' = '/partners/:id|PUT',
  'ManageGames' = '/games/*',
  'ReadGames' = '/games/:id|GET',
  'ReadInternalsupport' = '/internal-support/*',
}

export enum AdminTypeEnum {
  SuperAdmin = '10',
  Admin = '20',
}

export enum AdminStatusEnum {
  Active = '1',
  Pasive = '0',
}

export type Admin = {
  id: number | string;
  email: string;
  accessRightList: AdminAccessRightListEnum[];
  type: AdminTypeEnum;
  status: AdminStatusEnum;
  createdAt: string | Date;
  createdBy: string;
  updatedAt: string | Date;
  updatedBy: string | null;
};

export const AdminAccessRightListLabelMap: { [key: string]: string } = {
  [AdminAccessRightListEnum.AllPermissions]: 'All Permissions',
  [AdminAccessRightListEnum.ManageQuests]: 'Manage Quests',
  [AdminAccessRightListEnum.ReadQuests]: 'Read Quests',
  [AdminAccessRightListEnum.CreateQuests]: 'Create Quests',
  [AdminAccessRightListEnum.UpdateQuests]: 'Update Quests',
  [AdminAccessRightListEnum.ManageAirDropQuests]: 'Manage Airdrop Quests',
  [AdminAccessRightListEnum.ReadAirDropQuests]: 'Read Airdrop Quests',
  [AdminAccessRightListEnum.CreateAirDropQuests]: 'Create Airdrop Quests',
  [AdminAccessRightListEnum.UpdateAirDropQuests]: 'Update Airdrop Quests',
  [AdminAccessRightListEnum.ManageStoryInterSeason]: 'Manage Season Story',
  [AdminAccessRightListEnum.ReadStoryInterSeason]: 'Read Season Story',
  [AdminAccessRightListEnum.CreateStoryInterSeason]: 'Create Season Story',
  [AdminAccessRightListEnum.UpdateStoryInterSeason]: 'Update Season Story',
  [AdminAccessRightListEnum.ManageStoryFollowUp]: 'Manage Follow-Up',
  [AdminAccessRightListEnum.ReadStoryFollowUp]: 'Read Follow-Up',
  [AdminAccessRightListEnum.CreateStoryFollowUp]: 'Create Follow-Up',
  [AdminAccessRightListEnum.UpdateStoryFollowUp]: 'Update Follow-Up',
  [AdminAccessRightListEnum.ManageAdmins]: 'Manage Admins',
  [AdminAccessRightListEnum.ReadAdmins]: 'Read Admins',
  [AdminAccessRightListEnum.CreateAdmins]: 'Create Admins',
  [AdminAccessRightListEnum.UpdateAdmins]: 'Update Admins',
  [AdminAccessRightListEnum.ManageAdminlogs]: 'Manage Adminlogs',
  [AdminAccessRightListEnum.ReadAdminlogs]: 'Read Adminlogs',
  [AdminAccessRightListEnum.ManagePartners]: 'Manage Partners',
  [AdminAccessRightListEnum.ReadPartners]: 'Read Partners',
  [AdminAccessRightListEnum.CreatePartners]: 'Create Partners',
  [AdminAccessRightListEnum.UpdatePartners]: 'Update Partners',
  [AdminAccessRightListEnum.ManageGames]: 'Manage Games',
  [AdminAccessRightListEnum.ReadGames]: 'Read Games',
  [AdminAccessRightListEnum.ReadInternalsupport]: 'Read Internalsupport',
};

export const AdminTypeLabelMap: { [key: string]: string } = {
  [AdminTypeEnum.SuperAdmin]: 'Super Admin',
  [AdminTypeEnum.Admin]: 'Admin',
};

export const AdminStatusLabelMap: { [key: string]: string } = {
  [AdminStatusEnum.Active]: 'Active',
  [AdminStatusEnum.Pasive]: 'Passive',
};

export type Me = Admin & Pick<User, 'name' | 'photo'>;
