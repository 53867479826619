import React from 'react';
import {
  Edit,
  SaveButton,
  ToolbarProps,
  Toolbar,
  useNotify,
} from 'react-admin';
import AdminForm from '../components/AdminForm/AdminForm';
import HeaderTitle from '../../Shared/HeaderTitle/HeaderTitle';
import ResourceDetailTopToolbar from '../../Shared/ResourceDetailTopToolbar/ResourceDetailTopToolbar';
import { useMediaQuery } from '@mui/material';
import { getMe, updateMe } from '../../Admin/Admin.utilities';

type FormField = any;
type FormData = any;

const EditActions: React.FC<ToolbarProps> = (props) => (
  <Toolbar
    {...props}
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0 24px 0 24px !important',
      position: 'sticky',
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 2,
    }}
  >
    <SaveButton />
  </Toolbar>
);

const AdminEdit: React.FC = (props) => {
  const notify = useNotify();
  // const redirect = useRedirect();
  // const refresh = useRefresh();
  const isSmallDevice = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );

  const onSuccess = (data: FormData): void => {
    notify(`Changes to admin "${data.email}" saved`);
    // redirect('list', 'admins');

    if (data.email === getMe().email) {
      updateMe({ accessRightList: data.accessRightList });
    }

    // NOTICE: Don't use refresh it causes re-render and
    // sends multiple update requests one after another!
    // refresh();
  };

  // const onSettled = (data: FormData): void => {};

  /**
   * Removes the fields with an empty string value from object.
   *
   * @param data Admin
   * @returns Admin
   */
  const transform = (data: FormData): FormData => {
    const sanitizedData: { [key: string]: FormField } = {};

    for (const key in data) {
      if (
        (typeof data[key] === 'string' && data[key].length === 0) ||
        data[key] === null
      )
        continue;

      sanitizedData[key] = data[key];
    }

    return {
      ...sanitizedData,
      createdAt: undefined,
      id: undefined,
      createdBy: undefined,
      updatedAt: undefined,
      updatedBy: undefined,
    };
  };

  return (
    <Edit
      transform={transform}
      queryOptions={{
        onSuccess,
        // onSettled,
      }}
      actions={
        <ResourceDetailTopToolbar
          resource="Admin"
          hasBackButton
          hasDeleteButton
        />
      }
      title={<HeaderTitle />}
      {...(isSmallDevice && {
        sx: {
          marginBottom: '2rem',
          '& .MuiPaper-root': {
            border: 'none',
            boxShadow: 'none',
            height: 'auto',
          },
          '& .MuiCardContent-root': {
            padding: 0,
          },
        },
      })}
      {...props}
    >
      <AdminForm toolbar={<EditActions />} />
    </Edit>
  );
};

export default AdminEdit;
