import React, { FC, useEffect, useMemo } from 'react';

import {
  CreateButton,
  ListBase,
  SimpleListConfigurable,
  TextField,
  Title,
  useNotify,
  DateField,
  FunctionField,
  TopToolbar,
  DatagridConfigurable,
  ExportButton,
  Pagination,
  ChipField,
  useResourceContext,
  useStore,
  SelectColumnsButton,
  useRedirect,
} from 'react-admin';
import { Stack, useMediaQuery } from '@mui/material';
import { WithLoading } from '../../Shared/Loading';
import { AdminStatusLabelMap } from '../../Shared/Admin.types';
import { EmptyFieldWithRecord } from '../../Shared/EmptyFieldWithRecord';
import * as S from './PartnerList.styled';
import { useResetList } from '../../Shared/List.hooks';

const ListActions: React.FC<{ isSmallDevice: boolean }> = ({
  isSmallDevice,
}) => {
  const resource = useResourceContext();
  const preferenceKey = `preferences.${resource}`;
  const [, setDatagridColumns] = useStore(
    `${preferenceKey}.datagrid.columns`,
    []
  );
  const DEFAULT_COLUMNS_TO_SHOW = useMemo(
    () => ['0', '2', '4', '5', '6', '8'],
    []
  );

  useEffect(() => {
    setDatagridColumns(DEFAULT_COLUMNS_TO_SHOW as never[]);
  }, [DEFAULT_COLUMNS_TO_SHOW, setDatagridColumns]);

  return (
    <Stack
      direction={isSmallDevice ? 'column' : 'row'}
      justifyContent="space-between"
      marginTop={2}
      marginBottom={2}
    >
      <TopToolbar
        sx={{
          backgroundColor: 'transparent!important',
          width: '100%',
        }}
      >
        {!isSmallDevice && <SelectColumnsButton />}
        <CreateButton />
        <ExportButton />
      </TopToolbar>
    </Stack>
  );
};
const PartnerList: FC = () => {
  const isSmallDevice = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );
  const notify = useNotify();
  const redirect = useRedirect();

  useResetList();
  const onError = (error: any): void => {
    notify(`Could not load list: ${error.message}`, { type: 'warning' });
    redirect('/dashboard');
  };
  return (
    <ListBase
      debounce={500}
      perPage={10}
      queryOptions={{ onError }}
      sort={{ field: 'id', order: 'ASC' }}
      filterDefaultValues={{
        page: 1,
      }}
      filter={{
        page: 1,
      }}
    >
      <Title title="Galactic Entertainment" />
      <ListActions isSmallDevice={isSmallDevice} />
      <WithLoading>
        <>
          {isSmallDevice ? (
            <SimpleListConfigurable
              sx={{
                '& .RaSimpleList-tertiary': { opacity: 1 },
                '& .MuiListItem-gutters': {
                  borderBottom: '1px solid #f6f5f5',
                },
              }}
              primaryText={(record) => record.name}
              secondaryText={(record) => record.website}
              tertiaryText={(record) => (
                <S.StyledImageField
                  source="logo"
                  record={{
                    logo: record.logo,
                  }}
                  title={record.logo}
                  isSmallDevice={isSmallDevice}
                />
              )}
              linkType="show"
            />
          ) : (
            <DatagridConfigurable bulkActionButtons={false} rowClick="show">
              <EmptyFieldWithRecord source="id">
                <TextField source="id" />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord source="createdAt" label="Created At">
                <DateField source="createdAt" />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord source="name">
                <TextField source="name" />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord source="description">
                <TextField
                  source="description"
                  style={{
                    display: 'inline-block',
                    maxWidth: '20em',
                  }}
                />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord source="logo">
                <FunctionField
                  render={(record: any) => {
                    return (
                      <S.StyledImageField
                        source="logo"
                        record={{
                          logo: record.logo,
                        }}
                        title={record.logo}
                        isSmallDevice={isSmallDevice}
                      />
                    );
                  }}
                />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord source="website">
                <TextField source="website" />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord source="category">
                <ChipField source="category" />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord source="type">
                <ChipField source="type" />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord source="status">
                <FunctionField
                  render={(record: any) => {
                    return (
                      <ChipField
                        source="status"
                        record={{
                          status: AdminStatusLabelMap[record?.status as string],
                        }}
                        style={{
                          backgroundColor:
                            record?.status === '1' ? '#ff5722' : '#DEDEDE',
                          color: record?.status === '1' ? '#ffffff' : '#000000',
                        }}
                      />
                    );
                  }}
                />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord source="financialInfo">
                <TextField source="financialInfo" />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord source="contactInfo">
                <TextField source="contactInfo" />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord source="createdBy" label="Created By">
                <TextField source="createdBy" />
              </EmptyFieldWithRecord>
            </DatagridConfigurable>
          )}
          <Pagination />
        </>
      </WithLoading>
    </ListBase>
  );
};

export default PartnerList;
