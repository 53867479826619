import React from 'react';
import { Create, useNotify, useRedirect } from 'react-admin';
import HeaderTitle from '../../Shared/HeaderTitle/HeaderTitle';
import { CustomTopToolbarActions } from '../../Shared/CustomTopToolbarActions';
import { AirdropQuestForm } from '../components/AirdropQuestForm';

const AirdropQuestCreate: React.FC = () => {
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = (): void => {
    notify(`AdQuest created successfully`);
    redirect('edit', 'adquests');
  };

  return (
    <Create
      mutationOptions={{ onSuccess }}
      title={<HeaderTitle />}
      actions={
        <CustomTopToolbarActions title="Create Airdrop Quest" hasBackButton />
      }
    >
      <AirdropQuestForm />
    </Create>
  );
};

export default AirdropQuestCreate;
