import React from 'react';
import * as S from './QuestDetailContext.styled';

type QuestDetailContentProps = {
  claimDetails: string;
  description: string;
  details: string;
};

const QuestDetailContent: React.FC<QuestDetailContentProps> = ({
  claimDetails,
  description,
  details,
}) => {
  return (
    <S.QuestDetailContentWrapper>
      <fieldset>
        <legend>
          <S.QuestDetailContentTitle>Description</S.QuestDetailContentTitle>
        </legend>
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </fieldset>
      {details && (
        <fieldset>
          <legend>
            <S.QuestDetailContentTitle>Details</S.QuestDetailContentTitle>
          </legend>
          <div dangerouslySetInnerHTML={{ __html: details }} />
        </fieldset>
      )}
      {claimDetails && (
        <fieldset>
          <legend>
            <S.QuestDetailContentTitle>Claim Details</S.QuestDetailContentTitle>
          </legend>
          <div dangerouslySetInnerHTML={{ __html: claimDetails }} />
        </fieldset>
      )}
    </S.QuestDetailContentWrapper>
  );
};

export default QuestDetailContent;
