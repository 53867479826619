import React from 'react';
import {
  AutocompleteArrayInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormProps,
  TextInput,
} from 'react-admin';
import { AdminAccessRightListLabelMap } from '../../../Shared/Admin.types';
import { SELECT_REQUIRED_MESSAGE, validateEmail } from './AdminForm.utilities';

const AdminForm: React.FC<Omit<SimpleFormProps, 'children'>> = (props) => {
  return (
    <SimpleForm {...props}>
      <TextInput source="email" validate={validateEmail} fullWidth />
      {/*//TODO: when user selected "All Permissions", user should select others.*/}
      <AutocompleteArrayInput
        source="accessRightList"
        label="Access Rights"
        choices={Object.entries(AdminAccessRightListLabelMap).map(
          ([key, value]) => ({
            id: key,
            name: value,
          })
        )}
        validate={required(SELECT_REQUIRED_MESSAGE)}
        fullWidth
      />
      <SelectInput
        source="type"
        label="Role"
        choices={[
          { id: 10, name: 'Super Admin' },
          { id: 20, name: 'Admin' },
        ]}
        validate={required(SELECT_REQUIRED_MESSAGE)}
        fullWidth
      />
      <SelectInput
        source="status"
        label="Status"
        choices={[
          { id: 0, name: 'Passive' },
          { id: 1, name: 'Active' },
        ]}
        validate={required(SELECT_REQUIRED_MESSAGE)}
        fullWidth
      />
    </SimpleForm>
  );
};

export default AdminForm;
