import React from 'react';
import {
  BooleanField,
  FunctionFieldProps,
  useRecordContext,
} from 'react-admin';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOff from '@mui/icons-material/HighlightOff';
import { EmptyField } from './EmptyField';

type CustomBooleanFieldProps = Pick<FunctionFieldProps, 'label'> & {
  source: string;
};

export const CustomBooleanField: React.FC<CustomBooleanFieldProps> = ({
  source,
  label,
}) => {
  const record = useRecordContext();
  const fieldValue = source && record[source];
  const isEmpty = fieldValue === 'undefined' || fieldValue === null;

  return !isEmpty ? (
    <BooleanField
      source={source}
      label={label}
      TrueIcon={CheckCircleOutlineIcon}
      FalseIcon={HighlightOff}
      color={fieldValue ? 'green' : 'red'}
    />
  ) : (
    <EmptyField source={source} />
  );
};
