import React from 'react';
import {
  Button,
  useNotify,
  useRedirect,
  useRecordContext,
  useStore,
} from 'react-admin';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';

interface CopyButtonProps {
  storeKey: string;
  redirectPath: string;
  label?: string;
}

const CopyButton: React.FC<CopyButtonProps> = ({
  storeKey,
  redirectPath,
  label = 'Copy',
}) => {
  const record = useRecordContext();
  const redirect = useRedirect();
  const notify = useNotify();
  const [, setCopyData] = useStore(storeKey, {});
  const handleCopy = (e: any): void => {
    e.stopPropagation();
    if (record) {
      setCopyData(JSON.stringify(record));

      notify('Record copied! Redirecting...', {
        type: 'info',
        autoHideDuration: 1000,
      });

      setTimeout(() => {
        redirect(redirectPath);
      }, 1000);
    }
  };

  return (
    <Tooltip title={label} arrow>
      <span>
        <Button size="small" onClick={handleCopy} label="">
          <ContentCopyIcon fontSize="small" />
        </Button>
      </span>
    </Tooltip>
  );
};

export default CopyButton;
