import React from 'react';
import { useRecordContext } from 'react-admin';
import { CustomTopToolbarActions } from '../CustomTopToolbarActions';
import { getFormattedDateText } from '../DateUtilities';

type ResourceDetailTopTollbarProps = {
  resource: string;
  hasShowButton?: boolean;
  hasBackButton?: boolean;
  hasEditButton?: boolean;
  hasDeleteButton?: boolean;
};

const ResourceDetailTopToolbar: React.FC<ResourceDetailTopTollbarProps> = ({
  resource,
  hasShowButton = false,
  hasBackButton = false,
  hasEditButton = false,
  hasDeleteButton = false,
}) => {
  const { id, createdAt } = useRecordContext() || {};
  const formattedDate = getFormattedDateText(createdAt);

  const title = `${resource} ${id ? ` #${id}` : ''}`;
  const detailTitle = ` created at${createdAt ? ` ${formattedDate}` : ''}`;

  return (
    <CustomTopToolbarActions
      title={title}
      detailTitle={detailTitle}
      hasShowButton={hasShowButton}
      hasBackButton={hasBackButton}
      hasEditButton={hasEditButton}
      hasDeleteButton={hasDeleteButton}
    />
  );
};

export default ResourceDetailTopToolbar;
