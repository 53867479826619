import React from 'react';
import { Loading as RALoading } from 'react-admin';
import * as S from './Loading.styled';

const Loading: React.FC = () => {
  return (
    <S.LoadingWrapper>
      <RALoading />
    </S.LoadingWrapper>
  );
};

export default Loading;
