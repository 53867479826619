import styled from 'styled-components';

const QuestDetailContentTitle = styled.h1`
  margin: 0;
  color: #8ec0ed;
  font-size: 20px;
`;

const QuestDetailContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  fieldset {
    width: 100%;
    padding: 20px;
    border: 3px solid #8ec0ed;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  fieldset:not(:first-of-type) {
  }

  fieldset legend {
    margin-left: 15px;
  }
`;

export { QuestDetailContentTitle, QuestDetailContentWrapper };
