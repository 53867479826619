import React from 'react';
import { FilterFormProps as RAFilterFormProps } from 'react-admin';
import * as S from './FilterForm.styled';

export type FilterFormProps = RAFilterFormProps & { fullWidth: boolean };

const FilterForm: React.FC<FilterFormProps> = (props) => {
  return <S.FilterForm {...props} />;
};

export default FilterForm;
