import styled from 'styled-components';

const Container = styled.div`
  @import url('https://fonts.googleapis.com/css?family=Open+Sans|Nova+Mono');

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 6rem 16rem;

  @media (max-width: 768px) {
    padding: 1rem 1rem;
  }
`;

const Title = styled.h1`
  font-size: calc(4rem + 2vw);
  text-transform: uppercase;
  font-family: 'Nova Mono', monospace;
  margin-bottom: 0;
  margin-top: 0;
  color: #37474f;

  @media (max-width: 768px) {
    padding: 0 1rem;
  }
`;

const Text = styled.p`
  letter-spacing: 0.2rem;
  font-size: 2rem;
  margin: 1rem 0;
  color: #37474f;
`;

const Description = styled.p`
  letter-spacing: 0.2rem;
  font-size: 1.125rem;
  margin: 0;
  text-align: center;
`;

export { Container, Title, Text, Description };
