import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { FilterPayload, useListController } from 'react-admin';

export const useResetList = (
  defaultFilters: FilterPayload = { page: 1 }
): void => {
  const location = useLocation();
  const { setFilters } = useListController();

  useEffect(() => {
    if (window.location.search.length <= 0) {
      setFilters(defaultFilters, {});
    }

    return () => {
      if (window.location.search.length <= 0) {
        setFilters(defaultFilters, {});
      }
    };
  }, [location, setFilters, defaultFilters]);
};
