import React, { FC } from 'react';
import { SimpleForm, SimpleFormProps, TextInput } from 'react-admin';

const FollowUpForm: FC<Omit<SimpleFormProps, 'children'>> = (props) => {
  return (
    <SimpleForm {...props}>
      <TextInput source="title" label="Title" fullWidth />
      <TextInput source="message" label="Message" fullWidth />
    </SimpleForm>
  );
};

export default FollowUpForm;
