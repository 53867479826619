import React, { useEffect, useState } from 'react';
import { Card, CardContent } from '@mui/material';
import { HeaderTitle } from '../Shared/HeaderTitle';
import CardWithIcon from './CartWithIcon';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { useGetList } from 'react-admin';

const Dashboard: React.FC = () => {
  const [questCount, setQuestCount] = useState<number>();
  const { isLoading, data } = useGetList('quests', {
    pagination: { page: 1, perPage: 50000 },
    sort: { field: 'id', order: 'ASC' },
  });

  useEffect(() => {
    if (data) {
      setQuestCount(data.length);
    }
  }, [data]);

  return (
    <Card
      sx={{
        marginTop: '30px',
      }}
    >
      <HeaderTitle />
      <CardContent
        sx={{
          fontSize: '20px',
          fontWeight: 'bold',
          color: '#37474F',
        }}
      >
        Welcome to the Dashboard
      </CardContent>
      {!isLoading && (
        <CardWithIcon
          to="/quests"
          icon={ListAltIcon}
          title="Quest Count"
          subtitle={questCount}
        />
      )}
    </Card>
  );
};

export default Dashboard;
