import React from 'react';
import {
  ArrayField,
  ChipField,
  FunctionField,
  SingleFieldList,
  ArrayFieldProps,
  useRecordContext,
} from 'react-admin';
import { EmptyField } from '../EmptyField';

type CustomArrayFieldProps = Pick<ArrayFieldProps, 'label'> & {
  emptyText?: React.ReactNode;
  source: string;
  transform?: (value: string) => string;
};

const CustomArrayField: React.FC<CustomArrayFieldProps> = ({
  label,
  source,
  transform,
}) => {
  const record = useRecordContext();
  const fieldValue = source && record[source];

  return fieldValue && fieldValue.length ? (
    <ArrayField source={source} label={label}>
      <SingleFieldList>
        <FunctionField
          render={(recordValue: string) => {
            return (
              <ChipField
                record={{
                  [source]: transform ? transform(recordValue) : recordValue,
                }}
                source={source}
              />
            );
          }}
        />
      </SingleFieldList>
    </ArrayField>
  ) : (
    <EmptyField source={source} />
  );
};

export default CustomArrayField;
