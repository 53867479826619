import styled, { css } from 'styled-components';

const Container = styled.div`
  @import url('https://fonts.googleapis.com/css?family=Open+Sans|Nova+Mono');

  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    width: 100%;
  }

  table caption {
    font-size: 1em;
    margin: 0.5em 0 0.75em;
  }

  table tr {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    padding: 0.35em;
    font-size: 0.5em;
  }

  table th,
  table td {
    padding: 0.5em;
    text-align: center;
  }

  table th {
    font-size: 1em;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
`;

const TableWrapper = styled.div`
  overflow: auto;
  width: 80vw;
  max-width: 80vw;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;
  width: 100%;
  margin-bottom: 1rem;
`;

const FormContainer = styled.div<{ shouldShow: boolean }>`
  ${({ shouldShow }) => css`
    display: flex;
    align-items: center;
    flex-direction: column;
    column-gap: 0.5rem;
    width: ${shouldShow ? '100%' : '0px'};
    height: ${shouldShow ? 'auto' : '0px'};
    overflow: hidden;
  `}
`;

const ErrorCodesContainer = styled.div<{ shouldShow: boolean }>`
  ${({ shouldShow }) => css`
    width: ${shouldShow ? '100%' : '0px'};
    height: ${shouldShow ? 'auto' : '0px'};
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    max-height: 80vh;
    overflow-y: auto;
  `}
`;

const ErrorCodesList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
`;

const ErrorCodesTitle = styled.h1`
  font-family: 'Open Sans', sans-serif;
  font-size: 1.7rem;
  color: #000000;
  margin: 0;
`;

const ErrorCodesItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #000000;
  margin-top: 1rem;
  font-size: 0.8rem;
`;

const NotFoundWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  font-size: 1.5rem;

  h2 {
    color: #000000;
    font-size: 1.5rem;
    margin: 0;
  }

  p {
    font-size: 1rem;
    margin-top: 1rem;
  }
`;

const QueryWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const QueryMessage = styled.div`
  margin-left: 0.5rem;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.7rem;
  color: red;
  margin: 0;
`;

export {
  Container,
  FormContainer,
  ErrorCodesContainer,
  ErrorCodesList,
  ErrorCodesTitle,
  ErrorCodesItem,
  TabsContainer,
  NotFoundWrapper,
  QueryWrapper,
  QueryMessage,
  TableWrapper,
};
