import React, { ReactElement } from 'react';
import { useRecordContext } from 'react-admin';
import EmptyField from '../EmptyField/EmptyField';

type EmptyFieldWithRecordProps = {
  children: React.ReactNode;
  source: string;
  label?: string | ReactElement;
  text?: React.ReactNode;
};

const EmptyFieldWithRecord: React.FC<EmptyFieldWithRecordProps> = ({
  children,
  source,
  text = '[Not Set]',
  label,
}) => {
  const record = useRecordContext();
  const isObject = source && typeof record?.[source] === 'object';

  const fieldValue = source && record[source];

  const isEmptyObject =
    isObject && fieldValue && Object.keys(record[source]).length === 0;

  return fieldValue && !isEmptyObject ? (
    <>{children}</>
  ) : (
    <EmptyField source={source} text={text} label={label} />
  );
};

export default EmptyFieldWithRecord;
