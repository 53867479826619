import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-admin';

import { HeaderTitle } from '../Shared/HeaderTitle';
import * as S from './ForbiddenPage.styled';
import LockIcon from '@mui/icons-material/Lock';

const ForbiddenPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div>
      <HeaderTitle />
      <S.Container>
        <LockIcon
          sx={{
            fontSize: '2.5rem !important',
            color: '#37474f',
          }}
        />
        <S.Title>403</S.Title>
        <S.Text>Access Denied/Forbidden</S.Text>
        <S.Description>
          You don&apos;t have permissions to access this page.
        </S.Description>
        <S.Description>
          Contact an administrator to get permissions or go to the home page and
          browser other pages
        </S.Description>
        <Button
          label="Go to home"
          sx={{
            minWidth: 'auto',
            color: '#ffffff',
            backgroundColor: '#EF6C00 !important',
            marginTop: '1rem',
          }}
          onClick={() => navigate('/')}
          size="large"
        />
      </S.Container>
    </div>
  );
};

export default ForbiddenPage;
