import { useEffect, useState } from 'react';
import {
  FieldRelations,
  Provider,
  ProviderActionType,
  ProviderContentType,
} from '../../../Shared/Quest.types';

const ProviderNameMap: { [key: string]: string } = {
  [Provider.YouTube]: 'YouTube',
  [Provider.Twitter]: 'Twitter',
};

const ProviderContentTypeNameMap: { [key: string]: string } = {
  [ProviderContentType.Channel]: 'Channel',
  [ProviderContentType.Video]: 'Video',
  [ProviderContentType.Short]: 'Short',
  [ProviderContentType.Account]: 'Account',
  [ProviderContentType.Post]: 'Post',
};

const ActionTypeOfProviderAndContentTypeNameMap: { [key: string]: string } = {
  [ProviderActionType.Subscribe]: 'Subscribe',
  [ProviderActionType.Watch]: 'Watch',
  [ProviderActionType.Like]: 'Like',
  [ProviderActionType.Comment]: 'Comment',
  [ProviderActionType.Retweet]: 'Retweet',
  [ProviderActionType.Refer]: 'Refer',
  [ProviderActionType.Follow]: 'Follow',
};

type FieldItem = {
  id: string;
  name: string;
};

const useProviderFields = (): any => {
  const providers = Object.keys(FieldRelations.Provider).map((key: string) => ({
    id: key,
    name: ProviderNameMap[key],
  }));
  const [selectedProvider, setSelectedProvider] = useState<
    Provider | undefined
  >();
  const [providerContentTypes, setProviderContentTypes] = useState<
    FieldItem[] | undefined
  >();
  const [selectedProviderContentType, setSelectedProviderContentType] =
    useState<ProviderContentType | undefined>();
  const [
    actionTypesOfProviderAndContentType,
    setActionTypesOfProviderAndContentType,
  ] = useState<string[]>();
  const [
    selectedActionTypesOfProviderAndContentType,
    setSelectedActionTypesOfProviderAndContentType,
  ] = useState<string[]>();

  const resetProvider = (): void => {
    setSelectedProviderContentType(undefined);
    setProviderContentTypes(undefined);
    setActionTypesOfProviderAndContentType(undefined);
  };

  const resetProviderContentType = (): void => {
    setActionTypesOfProviderAndContentType(undefined);
  };

  useEffect(() => {
    if (selectedProvider) {
      setProviderContentTypes(
        Object.keys(FieldRelations.Provider[selectedProvider].ContentType).map(
          (key: string) => ({
            id: key,
            name: ProviderContentTypeNameMap[key],
          })
        )
      );
    }
  }, [selectedProvider]);

  useEffect(() => {
    if (selectedProvider && selectedProviderContentType) {
      setActionTypesOfProviderAndContentType(
        FieldRelations.Provider[selectedProvider].ContentType[
          selectedProviderContentType
        ].ActionTypes.map((key: string) => ({
          id: key,
          name: ActionTypeOfProviderAndContentTypeNameMap[key],
        }))
      );
    }
  }, [selectedProvider, selectedProviderContentType]);

  return {
    providers,
    selectProvider: setSelectedProvider,
    selectedProvider,
    resetProvider,
    providerContentTypes,
    selectProviderContentType: setSelectedProviderContentType,
    selectedProviderContentType,
    resetProviderContentType,
    actionTypesOfProviderAndContentType,
    selectedActionTypesOfProviderAndContentType,
    selectActionTypesOfProviderAndContentType:
      setSelectedActionTypesOfProviderAndContentType,
  };
};

export default useProviderFields;
