export const ErrorCodes = [
  {
    code: '40001',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '40002',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '40003',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '40004',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '40005',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '40006',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '40007',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '40008',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '40009',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '400010',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '400011',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '400012',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '400013',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '400014',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '400015',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '400016',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '400017',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '400018',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '400019',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '400020',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '400021',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '400022',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '400023',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '400024',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '400025',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '400026',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '400027',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '400028',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '400029',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '400030',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '400031',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '400032',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '400033',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '400034',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '400035',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '400036',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '400037',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '400038',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '400039',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '400040',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '60035',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '60036',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '60037',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '50035',
    description: "Your provided credentials didn't match. Please connect again",
  },
  {
    code: '50036',
    description: 'Entity Id provided not correct',
  },
  {
    code: '50036',
    description: 'Hidden',
  },
];
