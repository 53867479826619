import React, { useEffect, useMemo } from 'react';
import {
  BooleanInput,
  ChipField,
  CreateButton,
  DatagridConfigurable,
  DateField,
  EditButton,
  ExportButton,
  FilterButton,
  FunctionField,
  ListBase,
  NumberField,
  Pagination,
  SelectColumnsButton,
  SelectField,
  SimpleListConfigurable,
  TextField,
  TextInput,
  Title,
  TopToolbar,
  useNotify,
  usePermissions,
  useRecordContext,
  useRedirect,
  useResourceContext,
  useStore,
} from 'react-admin';
import { Stack, Tooltip, useMediaQuery } from '@mui/material';
import * as S from './QuestList.styled';
import { QuestDetailContent } from '../components/QuestDetailContent';
import { CustomBooleanField } from '../../Shared/CustomBooleanField';
import styled from 'styled-components';
import { EmptyFieldWithRecord } from '../../Shared/EmptyFieldWithRecord';
import { CustomArrayField } from '../../Shared/CustomArrayField';
import { WithLoading } from '../../Shared/Loading';
import { checkPermission } from '../../Admin/Admin.utilities';
import { AdminAccessRightListEnum } from '../../Shared/Admin.types';
import { CopyButton } from '../../Shared/CopyButton';

const StyledTextInput = styled(TextInput)`
  .MuiInputBase-input:focus ~ div .MuiButtonBase-root {
    color: #ff5722;
  }
`;

const questFilters = (fullWidth: boolean): React.ReactNode[] => {
  return [
    <StyledTextInput
      key="filterSearchInput"
      label="Search"
      source="q"
      alwaysOn
      fullWidth={fullWidth}
      resettable
    />,
    <BooleanInput
      key="filterIsPublished"
      source="isActive"
      label="Is Active?"
    />,
  ];
};

const ListActions: React.FC = () => {
  const isSmallDevice = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );
  const resource = useResourceContext();
  const preferenceKey = `preferences.${resource}`;
  const [, setDatagridColumns] = useStore(
    `${preferenceKey}.datagrid.columns`,
    []
  );
  const DEFAULT_COLUMNS_TO_SHOW = useMemo(
    () => ['0', '1', '2', '4', '5', '7', '12', '22', '25'],
    []
  );
  const { permissions } = usePermissions();

  useEffect(() => {
    setDatagridColumns(DEFAULT_COLUMNS_TO_SHOW as never[]);
  }, [DEFAULT_COLUMNS_TO_SHOW, setDatagridColumns]);

  return (
    <Stack
      direction={isSmallDevice ? 'column' : 'row'}
      justifyContent="space-between"
      marginTop={2}
      marginBottom={2}
    >
      <S.FilterForm
        filters={questFilters(isSmallDevice)}
        fullWidth={isSmallDevice}
      />
      <TopToolbar
        sx={{
          backgroundColor: 'transparent!important',
        }}
      >
        {!isSmallDevice && <SelectColumnsButton />}
        <FilterButton filters={questFilters(isSmallDevice)} disableSaveQuery />
        {checkPermission(permissions, [
          AdminAccessRightListEnum.CreateQuests,
        ]) && <CreateButton />}
        <ExportButton />
      </TopToolbar>
    </Stack>
  );
};

const QuestDetailPanel: React.FC = () => {
  const { claimDetails, description, details } = useRecordContext();

  return (
    <QuestDetailContent
      claimDetails={claimDetails}
      description={description}
      details={details}
    />
  );
};
const QuestList: React.FC = () => {
  const isSmallDevice = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );
  const notify = useNotify();
  const redirect = useRedirect();
  const { permissions } = usePermissions();

  const onError = (error: any): void => {
    notify(`Could not load list: ${error.message}`, { type: 'warning' });
    redirect('/dashboard');
  };

  const hasUpdateButton = checkPermission(permissions, [
    AdminAccessRightListEnum.UpdateQuests,
  ]);

  const hasCopyButton = checkPermission(permissions, [
    AdminAccessRightListEnum.CreateQuests,
  ]);

  return (
    <ListBase
      debounce={500}
      perPage={10}
      queryOptions={{
        onError,
      }}
      sort={{ field: 'id', order: 'DESC' }}
      filterDefaultValues={{
        page: 1,
      }}
      filter={{
        page: 1,
      }}
    >
      <Title title="Galactic Entertainment" />
      <ListActions />
      <WithLoading>
        <>
          {isSmallDevice ? (
            <SimpleListConfigurable
              primaryText={(record) => record.name}
              secondaryText={(record) => record.description}
              tertiaryText={(record) => `${record.points} points`}
              linkType="show"
            />
          ) : (
            <DatagridConfigurable
              bulkActionButtons={hasUpdateButton as any}
              expand={<QuestDetailPanel />}
              isRowExpandable={(row) => row.description}
              rowClick="show"
              sx={{
                '& .column-actions': {
                  textAlign: 'right',
                  '& .MuiStack-root': {
                    justifyContent: 'flex-end',
                  },
                  '& .MuiButtonBase-root': {
                    minWidth: 'max-content',
                  },
                  '& .MuiButton-startIcon': {
                    marginRight: '0',
                  },
                },
              }}
            >
              <EmptyFieldWithRecord source="id">
                <TextField source="id" />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord source="createdAt" label="Created At">
                <DateField source="createdAt" />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord source="name">
                <TextField source="name" />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord source="description">
                <TextField source="description" />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord source="points">
                <TextField source="points" />
              </EmptyFieldWithRecord>
              <CustomBooleanField source="isActive" label="Is Active?" />
              <EmptyFieldWithRecord source="icon">
                <ChipField source="icon" emptyText="-" />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord source="orderShow">
                <NumberField source="orderShow" label="Order Show" />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord source="justOnce" label="Just Once?">
                <CustomBooleanField source="justOnce" />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord source="url">
                <TextField source="url" label="URL" emptyText="-" />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord source="details">
                <TextField source="details" />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord source="claimDetails" label="Claim Details">
                <TextField source="claimDetails" />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord source="provider">
                <ChipField source="provider" emptyText="-" />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord
                source="providerContentType"
                label="Provider Content Type"
              >
                <ChipField source="providerContentType" />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord
                source="providerContent"
                label="Provider Content"
              >
                <TextField source="providerContent" />
              </EmptyFieldWithRecord>
              <CustomArrayField
                source="providerActionType"
                label="Provider Action Type"
              />
              <EmptyFieldWithRecord source="actionLabel" label="Action Label">
                <TextField source="actionLabel" />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord source="claimLabel" label="Claim Label">
                <TextField source="claimLabel" />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord
                source="verificationType"
                label="Verification Type"
              >
                <SelectField
                  source="verificationType"
                  choices={[
                    { id: 'manuel-text', name: 'Manuel Text' },
                    { id: 'auto', name: 'Auto' },
                  ]}
                />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord
                source="verificationDelay"
                label="Verification Delay"
              >
                <NumberField source="verificationDelay" />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord source="period" label="Period">
                <NumberField source="period" />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord
                source="periodAllowedRepetition"
                label="Period Allowed Repetition"
              >
                <NumberField source="periodAllowedRepetition" />
              </EmptyFieldWithRecord>
              <CustomArrayField source="requiredInfo" label="Required Info" />
              <EmptyFieldWithRecord
                source="repetitionLimit"
                label="Repetition Limit"
              >
                <NumberField source="repetitionLimit" />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord source="userLimit" label="User Limit">
                <NumberField source="userLimit" />
              </EmptyFieldWithRecord>

              {(hasUpdateButton || hasCopyButton) && (
                <FunctionField
                  source="actions"
                  label="Actions"
                  className="column-actions"
                  render={(record: any) => (
                    <Stack direction="row" justifyContent="flex-end">
                      {hasUpdateButton && (
                        <Tooltip title="Edit" arrow>
                          <span>
                            <EditButton record={record} label="" />
                          </span>
                        </Tooltip>
                      )}

                      {hasCopyButton && (
                        <CopyButton
                          storeKey="questCopiedRow"
                          redirectPath="/quests/create"
                        />
                      )}
                    </Stack>
                  )}
                />
              )}
            </DatagridConfigurable>
          )}
          <Pagination />
        </>
      </WithLoading>
    </ListBase>
  );
};

export default QuestList;
