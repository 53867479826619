import React, { FC } from 'react';
import {
  CreateButton,
  Datagrid,
  EditButton,
  ExportButton,
  FunctionField,
  ListBase,
  ShowButton,
  SimpleListConfigurable,
  TextField,
  TextInput,
  Title,
  TopToolbar,
  usePermissions,
} from 'react-admin';
import { EmptyFieldWithRecord } from '../../../Shared/EmptyFieldWithRecord';
import { WithLoading } from '../../../Shared/Loading';
import { Box, Stack, Tooltip, useMediaQuery } from '@mui/material';
import { FilterForm } from '../../../Shared/FilterForm';
import styled from 'styled-components';
import { checkPermission } from '../../../Admin/Admin.utilities';
import { AdminAccessRightListEnum } from '../../../Shared/Admin.types';

const truncate = (text: string, maxLength: number): string => {
  if (text.length <= maxLength) return text;
  return `${text.substring(0, maxLength)}...`;
};

const StyledTextInput = styled(TextInput)`
  .MuiInputBase-input:focus ~ div .MuiButtonBase-root {
    color: #ff5722;
  }
`;

const filters = (fullWidth: boolean): React.ReactNode[] => {
  return [
    <StyledTextInput
      key="filterSearchInput"
      label="Search"
      source="q"
      alwaysOn
      fullWidth={fullWidth}
      resettable
    />,
  ];
};

const ListActions: React.FC = () => {
  const isSmallDevice = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );

  const { permissions } = usePermissions();

  const hasCreateButton = checkPermission(permissions, [
    AdminAccessRightListEnum.CreateStoryFollowUp,
  ]);

  return (
    <Stack
      direction={isSmallDevice ? 'column' : 'row'}
      marginTop={2}
      marginBottom={2}
    >
      <FilterForm filters={filters(isSmallDevice)} fullWidth={isSmallDevice} />
      <TopToolbar
        sx={{
          backgroundColor: 'transparent!important',
          width: '100%',
        }}
      >
        {hasCreateButton && <CreateButton />}
        <ExportButton />
      </TopToolbar>
    </Stack>
  );
};

const FollowUpList: FC = () => {
  const isSmallDevice = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );

  const { permissions } = usePermissions();

  const hasUpdateButton = checkPermission(permissions, [
    AdminAccessRightListEnum.UpdateStoryFollowUp,
  ]);

  return (
    <ListBase perPage={10} sort={{ field: 'id', order: 'DESC' }}>
      <Title title="Galactic Entertainment" />
      <ListActions />
      <WithLoading>
        {isSmallDevice ? (
          <SimpleListConfigurable
            primaryText={(record) => record.titleStory}
            secondaryText={(record) => record.titleConclusion}
            tertiaryText={(record) => record.status}
            linkType="show"
          />
        ) : (
          <Datagrid
            bulkActionButtons={hasUpdateButton as any}
            rowClick="show"
            sx={{
              '& .column-actions': {
                textAlign: 'right',
                '& .MuiStack-root': {
                  justifyContent: 'flex-end',
                },
                '& .MuiButtonBase-root': {
                  minWidth: 'max-content',
                },
                '& .MuiButton-startIcon': {
                  marginRight: '0',
                },
              },
            }}
          >
            <EmptyFieldWithRecord source="id" label="ID">
              <TextField source="id" label="ID" />
            </EmptyFieldWithRecord>

            <EmptyFieldWithRecord source="title" label="Title">
              <TextField source="title" />
            </EmptyFieldWithRecord>
            <EmptyFieldWithRecord source="message" label="Message">
              <FunctionField
                source="message"
                label="Message"
                render={(record: any) => {
                  return (
                    <Tooltip
                      title={
                        <Box
                          sx={{
                            maxHeight: '150px',
                            overflowY: 'auto',
                          }}
                        >
                          {record.message}
                        </Box>
                      }
                      arrow
                    >
                      <span>{truncate(record.message, 30)}</span>
                    </Tooltip>
                  );
                }}
              />
            </EmptyFieldWithRecord>

            <FunctionField
              source="actions"
              label="Actions"
              className="column-actions"
              render={(record: any) => {
                return (
                  <Stack direction="row" justifyContent="flex-end">
                    {hasUpdateButton && (
                      <Tooltip title="Edit" arrow>
                        <span>
                          <EditButton record={record} label="" />
                        </span>
                      </Tooltip>
                    )}

                    <Tooltip title="Show" arrow>
                      <span>
                        <ShowButton record={record} label="" />
                      </span>
                    </Tooltip>
                  </Stack>
                );
              }}
            />
          </Datagrid>
        )}
      </WithLoading>
    </ListBase>
  );
};

export default FollowUpList;
