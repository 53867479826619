import { defaultTheme } from 'react-admin';
import deepOrange from '@mui/material/colors/deepOrange';
import red from '@mui/material/colors/red';

export const myTheme = {
  ...defaultTheme,
  palette: {
    primary: deepOrange,
    secondary: {
      main: '#37474f',
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  overrides: {},
  components: {
    ...defaultTheme.components,
    RaDatagrid: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          border: '1px solid rgba(224, 224, 224, 1)',
          borderRadius: '5px',
          boxShadow: '1px 1px 15px 3px rgb(224 224 224)',
          '& .RaDatagrid-headerCell': {
            color: '#393E46',
            fontWeight: 600,
            backgroundColor: '#fafafa',
            paddingTop: 16,
            paddingBottom: 16,
          },
          '& .RaDatagrid-rowCell': {
            paddingTop: 8,
            paddingBottom: 8,
          },
        },
      },
    },
    RaRichTextInput: {
      styleOverrides: {
        root: {
          '& .ProseMirror': {
            '&:focus': {
              outline: 0,
            },
          },
        },
      },
    },
    RaEdit: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            backgroundColor: '#ffffff',
            border: '1px solid rgba(224, 224, 224, 1)',
            borderRadius: '5px',
            boxShadow: '1px 1px 15px 3px rgb(224 224 224)',
            overflow: 'auto',
            maxHeight: '50rem',
            minHeight: 'auto',
            height: 'auto',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff !important',
          input: {
            backgroundColor: '#ffffff',
            '&:-webkit-autofill': {
              transition:
                'background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s',
            },
            '&:hover, &:focus': {
              backgroundColor: '#ffffff',
            },
          },
          '& .MuiSelect-select': {
            backgroundColor: '#ffffff',
            '&:hover, &:focus': {
              backgroundColor: '#ffffff',
            },
          },
        },
      },
    },
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
};
