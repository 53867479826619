export enum AirdropQuestType {
  CheckReddit = 'Check this out Reddit',
  CheckSpecial = 'Check this out Special',
  CheckX = 'Check this out X',
  CheckAmazon = 'Check this out Amazon',
  CheckInstagram = 'Check this out Instagram',
  LikeCommentYoutube = 'Like and Comment Youtube',
  TriviaKnowledge = 'Trivia Knowledge',
  UserInput = 'User Input',
  LoginStreak = 'Login Streak',
  TagFriendX = 'Tag a Friend X',
  FollowX = 'Follow X',
  LikeCommentInstagram = 'Like and Comment Instagram',
  LikeCommentX = 'Like and Comment X',
  TagFriendInstagram = 'Tag a Friend Instagram',
}

export const AirdropPointsMapping: { [key in AirdropQuestType]: number } = {
  [AirdropQuestType.CheckAmazon]: 2500,
  [AirdropQuestType.CheckInstagram]: 1000,
  [AirdropQuestType.CheckReddit]: 5000,
  [AirdropQuestType.CheckSpecial]: 7500,
  [AirdropQuestType.CheckX]: 1000,
  [AirdropQuestType.FollowX]: 5000,
  [AirdropQuestType.LikeCommentInstagram]: 2500,
  [AirdropQuestType.LikeCommentX]: 5000,
  [AirdropQuestType.LikeCommentYoutube]: 2500,
  [AirdropQuestType.LoginStreak]: 30000,
  [AirdropQuestType.TagFriendInstagram]: 2000,
  [AirdropQuestType.TagFriendX]: 2000,
  [AirdropQuestType.TriviaKnowledge]: 2500,
  [AirdropQuestType.UserInput]: 100000,
};

export const AirdropQuestTypeLabelMap: { [key: string]: string } = {
  [AirdropQuestType.CheckAmazon]: 'Check this out Amazon',
  [AirdropQuestType.CheckInstagram]: 'Check this out Instagram',
  [AirdropQuestType.CheckReddit]: 'Check this out Reddit',
  [AirdropQuestType.CheckSpecial]: 'Check this out Special',
  [AirdropQuestType.CheckX]: 'Check this out X',
  [AirdropQuestType.FollowX]: 'Follow X',
  [AirdropQuestType.LikeCommentInstagram]: 'Like and Comment Instagram',
  [AirdropQuestType.LikeCommentX]: 'Like and Comment X',
  [AirdropQuestType.LikeCommentYoutube]: 'Like and Comment Youtube',
  [AirdropQuestType.LoginStreak]: 'Login Streak',
  [AirdropQuestType.TagFriendInstagram]: 'Tag a Friend Instagram',
  [AirdropQuestType.TagFriendX]: 'Tag a Friend X',
  [AirdropQuestType.TriviaKnowledge]: 'Trivia Knowledge',
  [AirdropQuestType.UserInput]: 'User Input',
};
