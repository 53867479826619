// in src/MyLogoutButton.js
import * as React from 'react';
import { forwardRef } from 'react';
import { useLogout } from 'react-admin';
import MenuItem from '@mui/material/MenuItem';
import ExitIcon from '@mui/icons-material/PowerSettingsNew';

const Logout: React.FC<any> = forwardRef((props, ref) => {
  const logout = useLogout();
  const handleClick = (): any => logout();

  return (
    <MenuItem onClick={handleClick} ref={ref as any}>
      <ExitIcon />
      &nbsp;Logout
    </MenuItem>
  );
});

Logout.displayName = 'Logout';

export default Logout;
