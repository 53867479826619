import React from 'react';
import {
  ChipField,
  TextField,
  useRecordContext,
  Show,
  SimpleShowLayout,
  RichTextField,
  SelectField,
  NumberField,
  TabbedShowLayout,
  WithRecord,
  usePermissions,
} from 'react-admin';
import { useMediaQuery, Divider, Box } from '@mui/material';
import { CustomBooleanField } from '../../Shared/CustomBooleanField';
import { QuestDetailContent } from '../components/QuestDetailContent';
import { HeaderTitle } from '../../Shared/HeaderTitle';
import { CustomArrayField } from '../../Shared/CustomArrayField';
import { EmptyField } from '../../Shared/EmptyField';
import ResourceDetailTopToolbar from '../../Shared/ResourceDetailTopToolbar/ResourceDetailTopToolbar';

import * as S from './QuestShow.styled';
import { checkPermission } from '../../Admin/Admin.utilities';
import { AdminAccessRightListEnum } from '../../Shared/Admin.types';

const QuestDetailPanel: React.FC = () => {
  const record = useRecordContext();

  return (
    <Box
      sx={{
        width: { xs: '100%', md: '40%' },
        margin: { xs: 'auto', md: '1em' },
      }}
    >
      <QuestDetailContent
        claimDetails={record?.claimDetails}
        description={record?.description}
        details={record?.details}
      />
    </Box>
  );
};

const QuestShow: React.FC = () => {
  const isSmallDevice = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );
  const { permissions } = usePermissions();
  const hasEditButton = checkPermission(permissions, [
    AdminAccessRightListEnum.UpdateQuests,
  ]);
  const hasShowButton = checkPermission(permissions, [
    AdminAccessRightListEnum.ReadQuests,
  ]);

  return (
    <Show
      actions={
        <ResourceDetailTopToolbar
          resource="Quest"
          hasBackButton
          hasShowButton={hasShowButton}
          hasEditButton={hasEditButton}
        />
      }
      {...(!isSmallDevice && {
        aside: <QuestDetailPanel />,
      })}
      title={<HeaderTitle />}
      sx={{
        paddingBottom: isSmallDevice ? '1rem' : 'auto',
      }}
    >
      <SimpleShowLayout
        divider={<Divider flexItem />}
        sx={{
          padding: isSmallDevice ? '4px 8px' : '8px 16px',
        }}
      >
        <WithRecord
          render={(record) => (
            <TabbedShowLayout
              sx={{
                '& .MuiTabs-flexContainer': {
                  justifyContent: isSmallDevice ? 'space-between' : 'unset',
                },
              }}
            >
              <S.StyledTab label="Summary" isSmallDevice={isSmallDevice}>
                {record.name ? (
                  <TextField source="name" />
                ) : (
                  <EmptyField source="name" />
                )}
                {record.description ? (
                  <TextField source="description" />
                ) : (
                  <EmptyField source="description" />
                )}
                {record.points ? (
                  <TextField source="points" />
                ) : (
                  <EmptyField source="points" />
                )}
                <CustomBooleanField source="isActive" label="Is Active?" />
                {record.icon ? (
                  <SelectField
                    source="icon"
                    choices={[
                      { id: 'Retweet', name: 'Retweet' },
                      { id: 'Follow Us', name: 'Follow Us' },
                    ]}
                  />
                ) : (
                  <EmptyField source="icon" />
                )}
                {record.orderShow ? (
                  <NumberField source="orderShow" label="Order Show" />
                ) : (
                  <EmptyField source="orderShow" />
                )}
                <CustomBooleanField source="justOnce" label="Just Once?" />
                {record.url ? (
                  <TextField source="url" label="URL" />
                ) : (
                  <EmptyField source="url" />
                )}
              </S.StyledTab>
              <S.StyledTab label="Details" isSmallDevice={isSmallDevice}>
                {record.details ? (
                  <RichTextField source="details" />
                ) : (
                  <EmptyField source="details" />
                )}
                {record.claimDetails ? (
                  <RichTextField source="claimDetails" label="Claim Details" />
                ) : (
                  <EmptyField source="claimDetails" />
                )}
                {record.actionLabel ? (
                  <TextField source="actionLabel" label="Action Label" />
                ) : (
                  <EmptyField source="actionLabel" />
                )}
                {record.claimLabel ? (
                  <TextField source="claimLabel" label="Claim Label" />
                ) : (
                  <EmptyField source="claimLabel" />
                )}
              </S.StyledTab>
              <S.StyledTab label="Provider" isSmallDevice={isSmallDevice}>
                {record.provider ? (
                  <ChipField source="provider" />
                ) : (
                  <EmptyField source="provider" />
                )}
                {record.providerContentType ? (
                  <ChipField
                    source="providerContentType"
                    label="Provider Content Type"
                  />
                ) : (
                  <EmptyField source="providerContentType" />
                )}
                {record.providerContent ? (
                  <TextField
                    source="providerContent"
                    label="Provider Content"
                  />
                ) : (
                  <EmptyField source="providerContent" />
                )}
                <CustomArrayField
                  source="providerActionType"
                  label="Provider Action Type"
                />
              </S.StyledTab>
              <S.StyledTab label="Operations" isSmallDevice={isSmallDevice}>
                <CustomArrayField source="requiredInfo" label="Required Info" />
                {record.verificationType ? (
                  <SelectField
                    source="verificationType"
                    label="Verification Type"
                    choices={[
                      { id: 'manuel-text', name: 'Manuel Text' },
                      { id: 'auto', name: 'Auto' },
                    ]}
                  />
                ) : (
                  <EmptyField source="verificationType" />
                )}
                {record.verificationDelay ? (
                  <NumberField
                    source="verificationDelay"
                    label="Verification Delay"
                  />
                ) : (
                  <EmptyField source="verificationDelay" />
                )}
                {record.period ? (
                  <NumberField source="period" />
                ) : (
                  <EmptyField source="period" />
                )}
                {record.periodAllowedRepetition ? (
                  <NumberField
                    source="periodAllowedRepetition"
                    label="Period Allowed Repetition"
                  />
                ) : (
                  <EmptyField source="periodAllowedRepetition" />
                )}
                {record.repetitionLimit ? (
                  <NumberField
                    source="repetitionLimit"
                    label="Repetition Limit"
                  />
                ) : (
                  <EmptyField source="repetitionLimit" />
                )}
                {record.userLimit ? (
                  <NumberField source="userLimit" label="User Limit" />
                ) : (
                  <EmptyField source="userLimit" />
                )}
              </S.StyledTab>
            </TabbedShowLayout>
          )}
        />
        {isSmallDevice && <QuestDetailPanel />}
      </SimpleShowLayout>
    </Show>
  );
};

export default QuestShow;
