// in src/MyLoginPage.js
import { useLogin, useNotify } from 'react-admin';
import React, { useCallback, useEffect } from 'react';
import GoogleButton from 'react-google-button';
import useOAuth from './useOAuth';
import * as S from './LoginPage.styled';

const LoginPage: React.FC<any> = ({ theme }) => {
  const login = useLogin();
  const notify = useNotify();
  const { disabled, startAuth, isAuthorized, user } = useOAuth();

  const handleGoogleButtonClick = useCallback(
    (e: any): void => {
      // e.preventDefault();
      startAuth();
    },
    [startAuth]
  );

  useEffect(() => {
    if (isAuthorized) {
      login({ user }).catch(() => notify('Invalid login!'));
    }
  }, [isAuthorized, login, notify, user]);

  return (
    <S.LoginPageWrapper>
      <GoogleButton disabled={disabled} onClick={handleGoogleButtonClick} />
    </S.LoginPageWrapper>
  );
};

export default LoginPage;
