import React from 'react';
import {
  CreateButton,
  EditButton,
  Datagrid,
  Title,
  ListBase,
  TextField,
  TopToolbar,
  ExportButton,
  Pagination,
  SimpleListConfigurable,
  FunctionField,
  TextInput,
  useNotify,
  useRedirect,
  UrlField,
  usePermissions,
} from 'react-admin';
import { Stack, Tooltip, useMediaQuery } from '@mui/material';
import styled from 'styled-components';
import { EmptyFieldWithRecord } from '../../Shared/EmptyFieldWithRecord';
import {
  AdminAccessRightListEnum,
  AdminTypeLabelMap,
} from '../../Shared/Admin.types';
import { FilterForm } from '../../Shared/FilterForm';
import { WithLoading } from '../../Shared/Loading';
import {
  AirdropQuestType,
  AirdropQuestTypeLabelMap,
} from '../../Shared/AirdropQuest.types';
import { CopyButton } from '../../Shared/CopyButton';
import { checkPermission } from '../../Admin/Admin.utilities';

const StyledTextInput = styled(TextInput)`
  .MuiInputBase-input:focus ~ div .MuiButtonBase-root {
    color: #ff5722;
  }
`;

const filters = (fullWidth: boolean): React.ReactNode[] => {
  return [
    <StyledTextInput
      key="filterSearchInput"
      label="Search"
      source="q"
      alwaysOn
      fullWidth={fullWidth}
      resettable
    />,
  ];
};

const ListActions: React.FC = () => {
  const isSmallDevice = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );
  const { permissions } = usePermissions();

  const hasCreateButton = checkPermission(permissions, [
    AdminAccessRightListEnum.CreateAirDropQuests,
  ]);

  return (
    <Stack
      direction={isSmallDevice ? 'column' : 'row'}
      marginTop={2}
      marginBottom={2}
    >
      <FilterForm filters={filters(isSmallDevice)} fullWidth={isSmallDevice} />
      <TopToolbar
        sx={{
          backgroundColor: 'transparent!important',
          width: '100%',
        }}
      >
        {hasCreateButton && <CreateButton />}
        <ExportButton />
      </TopToolbar>
    </Stack>
  );
};

const AirdropQuestList: React.FC = () => {
  const isSmallDevice = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );
  const notify = useNotify();
  const redirect = useRedirect();
  const { permissions } = usePermissions();

  const onError = (error: any): void => {
    console.error('Error loading list:', error);
    notify(`Could not load list: ${error.message}`, { type: 'warning' });
    redirect('/dashboard');
  };

  const hasUpdateButtons = checkPermission(permissions, [
    AdminAccessRightListEnum.UpdateAirDropQuests,
  ]);

  const hasCreateButton = checkPermission(permissions, [
    AdminAccessRightListEnum.CreateAirDropQuests,
  ]);

  return (
    <ListBase
      perPage={10}
      queryOptions={{ onError }}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Title title="Galactic Entertainment" />
      <ListActions />
      <WithLoading>
        <>
          {isSmallDevice ? (
            <SimpleListConfigurable
              primaryText={(record) => record.email}
              secondaryText={(record) => AdminTypeLabelMap[record.type]}
              linkType="show"
            />
          ) : (
            <Datagrid
              bulkActionButtons={hasUpdateButtons as any}
              sx={{
                '& .column-actions': {
                  textAlign: 'right',
                  '& .MuiStack-root': {
                    justifyContent: 'flex-end',
                  },
                  '& .MuiButtonBase-root': {
                    minWidth: 'max-content',
                  },
                  '& .MuiButton-startIcon': {
                    marginRight: '0',
                  },
                },
              }}
            >
              <EmptyFieldWithRecord source="id" label="ID">
                <TextField source="id" label="ID" />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord source="orderShow" label="Order Show">
                <TextField source="orderShow" />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord source="name" label="Name">
                <TextField source="name" />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord source="description" label="Description">
                <TextField source="description" />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord source="url" label="Url">
                <UrlField source="url" target="_blank" />
              </EmptyFieldWithRecord>
              <EmptyFieldWithRecord source="type" label="Type">
                <FunctionField
                  render={(record: any) => {
                    return (
                      <TextField
                        source="type"
                        record={{
                          type:
                            AirdropQuestTypeLabelMap[record?.type as string] ??
                            record.type,
                        }}
                      />
                    );
                  }}
                />
              </EmptyFieldWithRecord>

              <EmptyFieldWithRecord source="points" label="Points">
                <TextField source="points" label="Points" />
              </EmptyFieldWithRecord>

              {(hasUpdateButtons || hasCreateButton) && (
                <FunctionField
                  source="actions"
                  label="Actions"
                  className="column-actions"
                  render={(record: any) => {
                    const recordType = record?.type as AirdropQuestType;
                    const isAllowedType =
                      Object.values(AirdropQuestType).includes(recordType);

                    return isAllowedType ? (
                      <Stack direction="row" justifyContent="flex-end">
                        {hasUpdateButtons && (
                          <Tooltip title="Edit" arrow>
                            <span>
                              <EditButton record={record} label="" />
                            </span>
                          </Tooltip>
                        )}

                        {hasCreateButton && (
                          <CopyButton
                            storeKey="adQuestCopiedRow"
                            redirectPath="/adquests/create"
                          />
                        )}
                      </Stack>
                    ) : null;
                  }}
                />
              )}
            </Datagrid>
          )}
          <Pagination />
        </>
      </WithLoading>
    </ListBase>
  );
};

export default AirdropQuestList;
