import styled from 'styled-components';
import { Tab, TabProps } from 'react-admin';

const StyledTab = styled(Tab)<TabProps & { isSmallDevice: boolean }>`
  ${({ isSmallDevice }) =>
    isSmallDevice &&
    `
      font-size: 0.75rem;
      padding-left: 0;
      padding-right: 0;
    `};
`;

export { StyledTab };
