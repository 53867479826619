import { FilterForm as ReactAdminFilterForm } from 'react-admin';
import styled from 'styled-components';
import { FilterFormProps } from './FilterForm';

const FilterForm = styled(ReactAdminFilterForm)<FilterFormProps>`
  div[data-source~='isActive'] {
    border: 1px solid #e2e2e2;
    background-color: #e2e2e2;
    border-radius: 30px;
    flex-direction: row-reverse;
    align-items: center;
    width: auto;

    @media (max-width: 768px) {
      margin-top: 1rem;
    }

    .RaFilterFormInput-hideButton {
      margin-bottom: 0;

      path {
        fill: #ff5722;
      }
    }
  }

  ${({ fullWidth }) =>
    fullWidth
      ? `
          .RaFilterForm-filterFormInput {
            width: 100%
          }
        `
      : `
          && {
            align-items: center;
          }
        `};
`;

export { FilterForm };
