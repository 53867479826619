export enum Provider {
  Internal = 'internal',
  Twitter = 'twitter',
  Discord = 'discord',
  Telegram = 'telegram',
  Facebook = 'facebook',
  Instagram = 'instagram',
  Reddit = 'reddit',
  Medium = 'medium',
  YouTube = 'youtube',
  LinkedIn = 'linkedin',
  TikTok = 'tiktok',
  Spotify = 'spotify',
  EmailMarketing = 'emailmarketing',
}

export enum ProviderContentType {
  Channel = 'channel',
  Video = 'video',
  Short = 'short',
  Account = 'account',
  Post = 'post',
}

export enum ProviderActionType {
  Like = 'like',
  Retweet = 'retweet',
  Watch = 'watch',
  Subscribe = 'subscribe',
  Post = 'post',
  Refer = 'refer',
  Comment = 'comment',
  Follow = 'follow',
}

export enum VerificationType {
  ManuelImage = 'manuel-image',
  ManuelText = 'manuel-text',
  Auto = 'auto',
  AutoText = 'auto-text',
}

export type Quest = {
  id: number | string;
  name: string;
  description: string;
  points: number;
  isActive: boolean;
  icon?: string | null;
  orderShow: number;
  justOnce: boolean;
  url?: string | null;
  createdAt: string | Date;
  details: string;
  claimDetails: string;
  provider: string | Provider;
  providerContent: string;
  providerContentType: string | ProviderContentType;
  providerActionType: string[] | ProviderActionType[];
  verificationType: string;
  verificationDelay: number;
  actionLabel: string;
  claimLabel: string;
  period: number;
  periodAllowedRepetition: number;
  requiredInfo: string[];
  repetitionLimit: number;
  userLimit: number;
};

export type FieldRelationsType = {
  Provider: {
    [providerKey in Provider]: {
      ContentType: {
        [contentTypeKey in ProviderContentType]: {
          ActionTypes: ProviderActionType[];
        };
      };
    };
  };
};

export const FieldRelations: any = {
  Provider: {
    [Provider.YouTube]: {
      ContentType: {
        [ProviderContentType.Channel]: {
          ActionTypes: [ProviderActionType.Subscribe],
        },
        [ProviderContentType.Video]: {
          ActionTypes: [
            // ProviderActionType.Watch,
            ProviderActionType.Like,
            ProviderActionType.Comment,
          ],
        },
        [ProviderContentType.Short]: {
          ActionTypes: [ProviderActionType.Comment, ProviderActionType.Like],
        },
      },
    },
    [Provider.Twitter]: {
      ContentType: {
        [ProviderContentType.Account]: {
          ActionTypes: [ProviderActionType.Follow],
        },
        [ProviderContentType.Post]: {
          ActionTypes: [
            ProviderActionType.Like,
            ProviderActionType.Comment,
            ProviderActionType.Retweet,
          ],
        },
      },
    },
  },
};
