import { fetchUtils } from 'react-admin';
import { Admin, AdminAccessRightListEnum, Me } from '../Shared/Admin.types';
import { ADMIN_PANEL_SERVER_URL } from '../App.config';

export const saveToken = (token: string): void => {
  localStorage.setItem('token', token);
};

export const getToken = (): string => {
  return localStorage.getItem('token') as string;
};

export const removeToken = (): void => {
  localStorage.removeItem('token');
};

export const httpClient = (url: string, options: any = {}): Promise<any> => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }

  const token = getToken();

  options.headers.set('Authorization', `Bearer ${token}`);

  return fetchUtils.fetchJson(url, options);
};

export const fetchAdminMe = async (): Promise<Admin> => {
  try {
    const response = await httpClient(`${ADMIN_PANEL_SERVER_URL}/admins/me`);

    return response.json;
  } catch (error) {
    console.log(error);

    return {} as Admin;
  }
};

export const saveMe = (me: Me): void => {
  localStorage.setItem('me', JSON.stringify(me));
};

export const getMe = (): Me => {
  return JSON.parse(localStorage.getItem('me') as string);
};

export const updateMe = (updatedParameters: Partial<Me>): Me => {
  const me = getMe();
  const updatedMe = {
    ...me,
    ...updatedParameters,
  };

  saveMe(updatedMe);

  return updatedMe;
};

export const removeMe = (): void => {
  localStorage.removeItem('me');
};

export const getUserPermissions = (): Admin['accessRightList'] => {
  return getMe().accessRightList;
};

export const checkPermission = (
  permissions: AdminAccessRightListEnum[],
  requiredPermissions: AdminAccessRightListEnum[],
  onlySome = false
): boolean => {
  if (
    permissions.includes(AdminAccessRightListEnum.AllPermissions) ||
    requiredPermissions.every((requiredPermission) =>
      permissions.includes(requiredPermission)
    )
  ) {
    return true;
  }

  if (
    onlySome &&
    requiredPermissions.some((requiredPermission) =>
      permissions.includes(requiredPermission)
    )
  ) {
    return true;
  }

  if (
    [
      AdminAccessRightListEnum.ReadQuests,
      AdminAccessRightListEnum.CreateQuests,
      AdminAccessRightListEnum.UpdateQuests,
    ].some((permisson) => requiredPermissions.includes(permisson)) &&
    permissions.includes(AdminAccessRightListEnum.ManageQuests)
  ) {
    return true;
  }

  if (
    [
      AdminAccessRightListEnum.ReadAirDropQuests,
      AdminAccessRightListEnum.CreateAirDropQuests,
      AdminAccessRightListEnum.UpdateAirDropQuests,
    ].some((permisson) => requiredPermissions.includes(permisson)) &&
    permissions.includes(AdminAccessRightListEnum.ManageAirDropQuests)
  ) {
    return true;
  }

  if (
    [
      AdminAccessRightListEnum.ReadStoryInterSeason,
      AdminAccessRightListEnum.CreateStoryInterSeason,
      AdminAccessRightListEnum.UpdateStoryInterSeason,
    ].some((permisson) => requiredPermissions.includes(permisson)) &&
    permissions.includes(AdminAccessRightListEnum.ManageStoryInterSeason)
  ) {
    return true;
  }

  if (
    [
      AdminAccessRightListEnum.ReadStoryFollowUp,
      AdminAccessRightListEnum.CreateStoryFollowUp,
      AdminAccessRightListEnum.UpdateStoryFollowUp,
    ].some((permisson) => requiredPermissions.includes(permisson)) &&
    permissions.includes(AdminAccessRightListEnum.ManageStoryFollowUp)
  ) {
    return true;
  }

  if (
    [
      AdminAccessRightListEnum.ReadAdmins,
      AdminAccessRightListEnum.CreateAdmins,
      AdminAccessRightListEnum.UpdateAdmins,
    ].some((permission) => requiredPermissions.includes(permission)) &&
    permissions.includes(AdminAccessRightListEnum.ManageAdmins)
  ) {
    return true;
  }

  if (
    [
      AdminAccessRightListEnum.ReadPartners,
      AdminAccessRightListEnum.CreatePartners,
      AdminAccessRightListEnum.UpdatePartners,
    ].some((permisson) => requiredPermissions.includes(permisson)) &&
    permissions.includes(AdminAccessRightListEnum.ManagePartners)
  ) {
    return true;
  }

  if (
    [AdminAccessRightListEnum.ReadGames].some((permisson) =>
      requiredPermissions.includes(permisson)
    ) &&
    permissions.includes(AdminAccessRightListEnum.ManageGames)
  ) {
    return true;
  }

  return false;
};
