import React from 'react';
import { useRecordContext } from 'react-admin';

const AdminLogParamsDetail: React.FC = () => {
  const { params } = useRecordContext();

  return (
    <>
      <div style={{ fontWeight: 'bold' }}>Params</div>
      <span>{' {'}</span>
      {Object.entries(params).map(([key, value]) => {
        return (
          <div key={key}>
            <span style={{ fontWeight: 'bold' }}>&emsp;{key}: </span>
            <span>{JSON.stringify(value)}</span>
          </div>
        );
      })}
      <span>{'}'}</span>
    </>
  );
};

export default AdminLogParamsDetail;
