import React, { FC } from 'react';
import { Create, useNotify, useRedirect } from 'react-admin';
import HeaderTitle from '../../../Shared/HeaderTitle/HeaderTitle';
import { CustomTopToolbarActions } from '../../../Shared/CustomTopToolbarActions';
import { FollowUpForm } from '../components/FollowUpForm';

type FormField = any;
type FormData = any;

const FollowUpCreate: FC = () => {
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = (data: FormData): void => {
    notify(`Story Follow-Up created successfully`);
    redirect('edit', 'storymessages', data.id, data);
  };

  const transform = (data: FormData): FormData => {
    const sanitizedData: { [key: string]: FormField } = {};

    for (const key in data) {
      if (typeof data[key] === 'string' && data[key].length === 0) continue;

      sanitizedData[key] = data[key];
    }

    return sanitizedData;
  };

  return (
    <Create
      transform={transform}
      mutationOptions={{ onSuccess }}
      title={<HeaderTitle />}
      actions={
        <CustomTopToolbarActions title="Story Follow-Up" hasBackButton />
      }
    >
      <FollowUpForm />
    </Create>
  );
};

export default FollowUpCreate;
