import React, { FC } from 'react';
import { useMediaQuery, Divider } from '@mui/material';
import { ChipField, Show, SimpleShowLayout, TextField } from 'react-admin';
import ResourceDetailTopToolbar from '../../../Shared/ResourceDetailTopToolbar/ResourceDetailTopToolbar';
import { HeaderTitle } from '../../../Shared/HeaderTitle';
import { EmptyFieldWithRecord } from '../../../Shared/EmptyFieldWithRecord';

const InterSeasonShow: FC = () => {
  const isSmallDevice = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );

  return (
    <Show
      actions={
        <ResourceDetailTopToolbar
          resource="Season Story"
          hasBackButton
          hasEditButton
          hasDeleteButton
        />
      }
      title={<HeaderTitle />}
      sx={{
        paddingBottom: '16px',
      }}
    >
      <SimpleShowLayout
        divider={<Divider flexItem />}
        sx={{
          padding: isSmallDevice ? '4px 8px' : '8px 16px',
        }}
      >
        <TextField source="id" />
        <EmptyFieldWithRecord
          source="questId"
          label={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              Quest ID{' '}
              <span style={{ fontSize: '12px', marginLeft: '5px' }}>
                (Auto generated if not provided)
              </span>
            </div>
          }
        >
          <TextField source="questId" />
        </EmptyFieldWithRecord>
        <EmptyFieldWithRecord source="titleStory" label="Title Story">
          <TextField source="titleStory" />
        </EmptyFieldWithRecord>
        <EmptyFieldWithRecord source="messageStory" label="Message Story">
          <TextField source="messageStory" />
        </EmptyFieldWithRecord>
        <EmptyFieldWithRecord source="titleConclusion" label="Title Conclusion">
          <TextField source="titleConclusion" />
        </EmptyFieldWithRecord>
        <EmptyFieldWithRecord
          source="firstButtonMessage"
          label="First Button Message"
        >
          <TextField source="firstButtonMessage" />
        </EmptyFieldWithRecord>
        <EmptyFieldWithRecord
          source="firstMessageConclusion"
          label="First Message Conclusion"
        >
          <TextField source="firstMessageConclusion" />
        </EmptyFieldWithRecord>
        <EmptyFieldWithRecord
          source="secondButtonMessage"
          label="Second Button Message"
        >
          <TextField source="secondButtonMessage" />
        </EmptyFieldWithRecord>
        <EmptyFieldWithRecord
          source="secondMessageConclusion"
          label="Second Message Conclusion"
        >
          <TextField source="secondMessageConclusion" />
        </EmptyFieldWithRecord>
        <EmptyFieldWithRecord
          source="thirdButtonMessage"
          label="Third Button Message"
        >
          <TextField source="thirdButtonMessage" />
        </EmptyFieldWithRecord>
        <EmptyFieldWithRecord
          source="thirdMessageConclusion"
          label="Third Message Conclusion"
        >
          <TextField source="thirdMessageConclusion" />
        </EmptyFieldWithRecord>

        <EmptyFieldWithRecord source="status" label="Status">
          <ChipField source="status" emptyText="-" />
        </EmptyFieldWithRecord>
      </SimpleShowLayout>
    </Show>
  );
};

export default InterSeasonShow;
