import React, { useState } from 'react';
import { TextInput, SimpleForm, Button, useNotify } from 'react-admin';

import { HeaderTitle } from '../Shared/HeaderTitle';
import * as S from './InternalSupportPage.styled';
import { ADMIN_PANEL_SERVER_URL } from '../App.config';
import { getToken } from '../Admin/Admin.utilities';
import {
  UsersDataTable,
  UsersTwitterStatsTable,
  UsersTwitterTokensTable,
} from '../Shared/User/User.types';
import { ErrorCodes } from './InternalSupportPage.constants';
import { useNavigate } from 'react-router';
import axios, { isAxiosError } from 'axios';

const InternalSupportPage: React.FC = () => {
  const notify = useNotify();
  const navigate = useNavigate();

  const [walletId, setWalletId] = useState('');
  const [email, setEmail] = useState('');
  const [discordId, setDiscordId] = useState('');
  const [twitterHandle, setTwitterHandle] = useState('');
  const [referralCode, setReferralCode] = useState(false);
  const [foundUsersData, setFoundUsersData] = useState<UsersDataTable[] | null>(
    null
  );
  const [foundTwitterStats, setFoundTwitterStats] = useState<
    (UsersTwitterStatsTable & UsersTwitterTokensTable)[] | null
  >(null);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<'Users' | 'Error Codes'>('Users');

  const handleSubmit = async (): Promise<void> => {
    try {
      if (
        !walletId &&
        !email &&
        !discordId &&
        !twitterHandle &&
        !referralCode
      ) {
        throw new Error('Please provide at least one search parameter');
      }
      setFoundUsersData(null);
      setFoundTwitterStats(null);
      setIsSearchLoading(true);
      const bearerToken = getToken();
      const planetQuestUsersRes = await axios.get<{
        usersdata: UsersDataTable[];
        usersTwitterStats: (UsersTwitterStatsTable & UsersTwitterTokensTable)[];
      }>(`${ADMIN_PANEL_SERVER_URL}/internalsupport/users`, {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
        params: {
          walletId,
          email,
          discordId,
          twitterHandle,
          referralCode,
        },
      });
      setFoundUsersData(planetQuestUsersRes.data.usersdata);
      setFoundTwitterStats(planetQuestUsersRes.data.usersTwitterStats);
    } catch (error) {
      let errorMessage = 'An error occurred';
      if (error instanceof Error && !isAxiosError(error)) {
        errorMessage = error.message;
      } else if (isAxiosError(error)) {
        if (error.response?.status === 401) {
          navigate('/login');
        }
        if (error.response?.data.message) {
          errorMessage = error.response.data.message;
        }
      }
      notify(errorMessage, { type: 'error' });
    } finally {
      setIsSearchLoading(false);
    }
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <HeaderTitle />
      <S.Container>
        <S.TabsContainer>
          <Button
            label="Users"
            onClick={() => setActiveTab('Users')}
            sx={{
              backgroundColor:
                activeTab === 'Users'
                  ? '#37474f !important'
                  : '#FFFFFF !important',
              color:
                activeTab === 'Users'
                  ? '#FFFFFF !important'
                  : '#000000 !important',
            }}
            disabled={activeTab === 'Users'}
          />
          <Button
            label="Error Codes"
            onClick={() => setActiveTab('Error Codes')}
            sx={{
              backgroundColor:
                activeTab === 'Error Codes'
                  ? '#37474f !important'
                  : '#FFFFFF !important',
              color:
                activeTab === 'Error Codes'
                  ? '#FFFFFF !important'
                  : '#000000 !important',
            }}
            disabled={activeTab === 'Error Codes'}
          />
        </S.TabsContainer>
        <S.FormContainer shouldShow={activeTab === 'Users'}>
          <S.ErrorCodesTitle>Search User</S.ErrorCodesTitle>
          <SimpleForm
            onSubmit={handleSubmit}
            id="internal_support_user_search_form"
            toolbar={false}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                columnGap: '0.5rem',
                width: '100%',
                height: 'auto',
                overflow: 'hidden',
              }}
            >
              <TextInput
                source="Wallet Id"
                fullWidth
                onChange={(e) => setWalletId(e.target.value)}
              />
              <TextInput
                source="Email"
                fullWidth
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextInput
                source="Discord Id"
                fullWidth
                onChange={(e) => setDiscordId(e.target.value)}
              />
              <TextInput
                source="Twitter Handle"
                fullWidth
                onChange={(e) => setTwitterHandle(e.target.value)}
              />
              <TextInput
                source="Referral Code"
                fullWidth
                onChange={(e) => setReferralCode(e.target.value)}
              />
            </div>

            <S.QueryWrapper>
              <Button
                form="internal_support_user_search_form"
                label={isSearchLoading ? 'Querying...' : 'Query'}
                type="submit"
                size="large"
                disabled={
                  isSearchLoading ||
                  (!walletId &&
                    !email &&
                    !discordId &&
                    !twitterHandle &&
                    !referralCode)
                }
              />
              {!walletId &&
                !email &&
                !discordId &&
                !twitterHandle &&
                !referralCode && (
                  <S.QueryMessage>
                    * Provide at least one of the inputs to use the query
                  </S.QueryMessage>
                )}
            </S.QueryWrapper>
          </SimpleForm>
          {isSearchLoading && <div>Searching...</div>}
          {foundUsersData !== null && foundUsersData.length > 0 ? (
            <>
              <h2>UsersData Table</h2>
              <S.TableWrapper>
                <table>
                  <thead>
                    <tr>
                      <th>Nickname</th>
                      <th>Email</th>
                      <th>Discord Invite Code</th>
                      <th>Discord Id</th>
                      <th>Faction</th>
                      <th>Wallet Key</th>
                      <th>Referral Code</th>
                      <th>Referral Code Used</th>
                      <th>Verified</th>
                      <th>Created At</th>
                      <th>Email Confirmation Code</th>
                      <th>Salt Unique Id</th>
                      <th>Unique Id</th>
                      <th>Newsletter</th>
                      <th>Latest Known IP</th>
                    </tr>
                  </thead>
                  <tbody>
                    {foundUsersData.map((user) => (
                      <tr key={user.uniqueid}>
                        <td>{user.nickname}</td>
                        <td>{user.email}</td>
                        <td>{user.discordinvitecode}</td>
                        <td>{user.discordid}</td>
                        <td>{user.faction}</td>
                        <td>{user.walletkey}</td>
                        <td>{user.referralcode}</td>
                        <td>{user.referralcodeused}</td>
                        <td>{user.verified}</td>
                        <td>{user.createdat}</td>
                        <td>{user.emailconfirmationcode}</td>
                        <td>{user.saltuniqueid}</td>
                        <td>{user.uniqueid}</td>
                        <td>{user.newsletter}</td>
                        <td>{user.latestknownip}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </S.TableWrapper>
            </>
          ) : foundUsersData !== null && foundUsersData.length === 0 ? (
            <S.NotFoundWrapper>
              <h2>User Table</h2>
              <p>No users found in usersdata table</p>
            </S.NotFoundWrapper>
          ) : (
            <></>
          )}
          {foundTwitterStats !== null && foundTwitterStats.length > 0 ? (
            <>
              <h2>Twitter Stats Table</h2>
              <S.TableWrapper>
                <table>
                  <thead>
                    <tr>
                      <th>User Id</th>
                      <th>Twitter User Id</th>
                      <th>Name</th>
                      <th>Username</th>
                      <th>Profile Picture</th>
                      <th>Points</th>
                      <th>Level</th>
                      <th>Retweet Count</th>
                      <th>Reply Count</th>
                      <th>Like Count</th>
                      <th>Quote Count</th>
                      <th>Impression Count</th>
                      <th>Tweet Count</th>
                      <th>Common Crystal Count</th>
                      <th>Rare Crystal Count</th>
                      <th>Epic Crystal Count</th>
                      <th>Legendary Crystal Count</th>
                      <th>Created At</th>
                      <th>Updated At</th>
                      <th>Raw Quantum</th>
                      <th>Provider</th>
                      <th>Unique Id</th>
                      <th>Access Token</th>
                      <th>Refresh Token</th>
                      <th>Created At</th>
                      <th>Updated At</th>
                      <th>Provider User Id</th>
                      <th>Referral Code 1</th>
                      <th>Used Referral Code</th>
                      <th>Referral Code 2</th>
                      <th>Referral Code 3</th>
                      <th>Referral Code 4</th>
                      <th>Provider User Created At</th>
                      <th>Affiliate Code</th>
                      <th>Discord Id</th>
                      <th>Faction</th>
                      <th>Is New User</th>
                      <th>Soft Banned</th>
                    </tr>
                  </thead>
                  <tbody>
                    {foundTwitterStats.map((user) => (
                      <tr key={user.uniqueid}>
                        <td>{user.userid}</td>
                        <td>{user.twitteruserid}</td>
                        <td>{user.name}</td>
                        <td>{user.username}</td>
                        <td>{user.profilepicture}</td>
                        <td>{user.points}</td>
                        <td>{user.level}</td>
                        <td>{user.retweetcount}</td>
                        <td>{user.replycount}</td>
                        <td>{user.likecount}</td>
                        <td>{user.quotecount}</td>
                        <td>{user.impressioncount}</td>
                        <td>{user.tweetcount}</td>
                        <td>{user.commoncrsytalcount}</td>
                        <td>{user.rarecrystalcount}</td>
                        <td>{user.epiccrystalcount}</td>
                        <td>{user.legendarycrystalcount}</td>
                        <td>{user.createdat}</td>
                        <td>{user.updatedat}</td>
                        <td>{user.rawquantum}</td>
                        <td>{user.provider}</td>
                        <td>{user.uniqueid}</td>
                        <td>{user.accesstoken}</td>
                        <td>{user.refreshtoken}</td>
                        <td>{user.createdat}</td>
                        <td>{user.updatedat}</td>
                        <td>{user.provideruserid}</td>
                        <td>{user.referralcode1}</td>
                        <td>{user.usedreferralcode}</td>
                        <td>{user.referralcode2}</td>
                        <td>{user.referralcode3}</td>
                        <td>{user.referralcode4}</td>
                        <td>{user.providerusercreatedat}</td>
                        <td>{user.affiliatecode}</td>
                        <td>{user.discordid}</td>
                        <td>{user.faction}</td>
                        <td>{user.isnewuser}</td>
                        <td>{user.softbanned}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </S.TableWrapper>
            </>
          ) : foundTwitterStats !== null && foundTwitterStats.length === 0 ? (
            <S.NotFoundWrapper>
              <h2>Twitter Stats Table</h2>
              <p>
                No users found in userstwitterstats and userstwittertokens
                tables
              </p>
            </S.NotFoundWrapper>
          ) : (
            <></>
          )}
        </S.FormContainer>
        <S.ErrorCodesContainer shouldShow={activeTab === 'Error Codes'}>
          <S.ErrorCodesTitle>Error Codes</S.ErrorCodesTitle>
          <S.ErrorCodesList>
            {ErrorCodes.map((errorCode) => (
              <S.ErrorCodesItem key={JSON.stringify(errorCode)}>
                <div>{errorCode.code}</div>
                <div>{errorCode.description}</div>
              </S.ErrorCodesItem>
            ))}
          </S.ErrorCodesList>
        </S.ErrorCodesContainer>
      </S.Container>
    </div>
  );
};

export default InternalSupportPage;
